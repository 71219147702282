var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      staticClass: "popupAlert",
      attrs: { title: _vm.title, active: _vm.localShow },
      on: {
        "update:active": function ($event) {
          _vm.localShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("vs-icon", {
              attrs: { icon: _vm.icon, size: "large", color: "warning" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-4/5" },
          [
            _c("vs-input", {
              staticClass: "mr-3 w-full",
              attrs: { type: "password", label: "Senha de supervisor exigida" },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errorMessage))]
            ),
            _vm._t("default"),
          ],
          2
        ),
      ]),
      _c("hr"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto",
                  attrs: { color: "secondary" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.cancelButtonText))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: { color: _vm.actionButtonColor },
                  on: { click: _vm.action },
                },
                [_vm._v(_vm._s(_vm.actionButtonText))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }